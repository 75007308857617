import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Stack, Modal, ContextualMenu, useTheme } from '@fluentui/react';
import { useRecoilValue } from 'recoil';
import useSize from '../../utils/hooks/use-video-size';
import { userStreamState, displayStreamState, remoteStreamsState, RemoteStream } from '../../atoms';
import VideoBox from '../../comps/video';

const AR = 4 / 3;
const SIDE_LIST_WIDTH = 200;

const VideoBoxes: FunctionComponent = () => {
  const userMedia = useRecoilValue(userStreamState); // our media
  const displayMedia = useRecoilValue(displayStreamState);
  const remoteStreams = useRecoilValue(remoteStreamsState);

  const [pinnedItem, setPinnedItem] = useState<RemoteStream>();

  const remoteDisplay = remoteStreams.find((r) => r.isDisplay);
  useEffect(() => {
    if (remoteDisplay) setPinnedItem(remoteDisplay);
    else setPinnedItem(undefined);
  }, [remoteDisplay]);

  const { x, y, X } = useSize(remoteStreams.length, AR);
  const toHide =
    !userMedia?.getVideoTracks().length &&
    !remoteStreams.filter((remoteStream) => remoteStream.stream.getVideoTracks().length > 0).length &&
    !remoteStreams.filter((stream) => stream.isDisplay).length &&
    !pinnedItem?.stream?.getVideoTracks().length &&
    !displayMedia;
  // We use a variable to know if we need to only have the audio and hide the layout
  // We need to use a box of the audio otherwise it is no transmitted
  // TODO See if the audio can be used without the video stream
  return (
    <Layout toHide={toHide}>
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        {pinnedItem && !displayMedia && (
          <CameraBox>
            <VideoBox
              key={pinnedItem.stream.id}
              muted
              stream={pinnedItem.stream}
              label={pinnedItem.partnerName}
              noContextualMenu
            />
          </CameraBox>
        )}
        {displayMedia && (
          <CameraBox>
            <VideoBox
              key={displayMedia.id}
              muted
              stream={displayMedia}
              label="You are sharing your screen"
              noContextualMenu
            />
          </CameraBox>
        )}
      </div>
      <VideosLayout>
        {userMedia && userMedia.getVideoTracks().length > 0 && (
          <CameraBox>
            <VideoBox key={userMedia.id} muted stream={userMedia} label="You" noContextualMenu />
          </CameraBox>
        )}
        {remoteStreams
          .filter(({ isDisplay }) => isDisplay !== true)
          .map(({ stream, partnerName }) => (            
            <CameraBox key={stream.id}>
              <VideoBox key={stream.id} label={partnerName} stream={stream} noContextualMenu />
            </CameraBox>
          ))}
      </VideosLayout>
    </Layout>
  );
};

const VideosLayout = ({ children }: any) => (
  <div
    style={{
      display: 'grid',
      gap: '10px',
      height: '50%',
      gridTemplate: '1fr 1fr 1fr / 1fr 1fr 1fr 1fr'
    }}>
    {children}
  </div>
);
const Layout = ({ children, toHide }: any) => (
  <div
    style={{
      height: '58%',
      gap: '10px',
      gridTemplate: '100% / 1fr 1fr',
      margin: 'auto',
      display: toHide ? 'none' : 'grid',
      width: '90%',
      left: '50%',
      top: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)'
      // border: "solid 1px black"
    }}>
    {children}
  </div>
);

const CameraBox = ({ children }: any) => (
  <div
    style={{
      position: 'relative',
      borderRadius: '10px',
      // background: 'rgba(0,0,0,0.2)',
      width: '0',
      height: '0',
      padding: '40% 50%',
      maxWidth: '100%',
      maxHeight: '100%',
      zIndex: '100',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <VideoWrapper />
    {children}
  </div>
);
const InfoButton = ({ label, style }: any) => (
  <div
    style={{
      cursor: 'pointer',
      fontSize: '8px',
      background: 'rgba(0,0,0,0.5)',
      color: 'white',
      padding: '2px 5px',
      textTransform: 'uppercase',
      borderRadius: '2px',
      border: 'solid 1px rgba(255,255,255,0.4)',
      ...style
    }}>
    {label}
  </div>
);

const VideoWrapper = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }}
  />
);

export default VideoBoxes;

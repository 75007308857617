import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IPivotStyles } from '@fluentui/react';
import fscreen from 'fscreen';
import { roomState } from '../atoms';
import useAbort from '../utils/hooks/use-abort';
import { useUserMedia } from '../utils/hooks/use-streams';
import ExternalController from '../comps/ExternalController';
import App from '../app';
import { UnityCanva } from '../comps/UnityCanva/UnityCanva';

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    // display: 'flex',
    // justifyContent: 'center'
  },
  itemContainer: {
    padding: '.5em',
    width: '300px',
    height: '225px'
  }
};

const LandingNew: FunctionComponent = () => {
  const [room, setRoom] = useRecoilState(roomState);
  const { startUserMedia, stopUserMedia } = useUserMedia();
  const onAbort = useAbort();
  let defaultKey = 'create';
  let defaultId: string | undefined;
  const path = window.location.pathname;
  const REGEX = /^\/room\/(?<id>[0-9a-zA-Z-_]+)/;
  const match = path.match(REGEX);
  if (match) {
    defaultKey = 'join';
    defaultId = match.groups?.id;
  }
  useEffect(() => {
    if (fscreen.fullscreenElement) fscreen.exitFullscreen();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const handleSetVisible = async () => {
    setIsVisible(!isVisible);
    if (isVisible) {
      await stopUserMedia('videoinput');
      await stopUserMedia('audioinput');
      onAbort();
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        background: 'blue',
        boxSizing: 'border-box'
      }}>
      <ExternalController />
      <UnityCanva />
      {room && <App />}
    </div>
  );
};

export default LandingNew;

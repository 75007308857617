import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { nanoid } from 'nanoid';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDisplayMedia, useUserMedia } from '../utils/hooks/use-streams';
import {
  audioDevicesState,
  preferencesState,
  roomState,
  socketState,
  videoDevicesState
} from '../atoms';
import App from '../app';
import useAbort from '../utils/hooks/use-abort';

const functs = [
  'isBrowserChromeExternal',
  'openRoomExternal',
  'screenShareExternal',
  'updateNameExternal',
  'closeRoomExternal',
  'cutEveryoneButMeMicExternal',
  'cutMyMicExternal',
  'enableMyCamExternal',
  'cutMyCamExternal',
  'enableMyMicExternal'
];

const ExternalController = () => {
  const socket = useRecoilValue(socketState);
  const audioDevices = useRecoilValue(audioDevicesState);
  const videoDevices = useRecoilValue(videoDevicesState);
  const [room, setRoom] = useRecoilState(roomState);
  const { startUserMedia, stopUserMedia } = useUserMedia();
  const { displayMediaStatus, startDisplayMedia, stopDisplayMedia } = useDisplayMedia();
  const onAbort = useAbort();
  const [preferences, setPreferences] = useRecoilState(preferencesState);

  const handleClick = (e: any) => {
    const { id } = e.target;
    const args = JSON.parse(e.target.getAttribute('data-args'));
    switch (id) {
      case 'openRoomExternal':
        OpenRoomExternal(args[0], args[1]);
        break;
      case 'screenShareExternal':
        if (args) startDisplayMedia();
        else {
          stopDisplayMedia();
        }
        break;
      case 'closeRoomExternal':
        CloseRoomExternal();
        break;
      case 'updateNameExternal':
        preferences.name = args as string;
        setPreferences(preferences);
        break;
      case 'cutMyMicExternal':
        StopAudio();
        break;
      case 'enableMyCamExternal':
        StartVideo();
        break;
      case 'cutMyCamExternal':
        StopVideo();
        break;
      case 'cutEveryoneButMeMicExternal':
        MuteAll();
        break;
      case 'enableMyMicExternal':
        StartAudio();
        break;
      default:
        console.log('Not Implemented');
    }
  };

  const OpenRoomExternal = (id: string, useCam: boolean) => {
    const roomAlreadyOpened = room !== undefined && room !== null && room?.name === id;
    if (roomAlreadyOpened) {
      console.log('Already on room', id);
      return;
    }

    const anotherRoomAlreadyOpened = room !== undefined && room !== null && room?.name !== id;
    if (anotherRoomAlreadyOpened) {
      console.log('Closing room', room);
      CloseRoomExternal();
    }

    if (useCam) {
      StartVideoAndAudio();
    } else {
      StartAudio();
    }

    const roomId = id ?? nanoid();
    console.log('OpenRoomExternal Room Id', roomId, '. User name: ', preferences.name);
    socket.emit('join_default_room', roomId, preferences.name);
  };

  const CloseRoomExternal = () => {
    const stopMedia = async () => {
      await stopUserMedia();
      await stopDisplayMedia();
    };
    stopMedia();
    onAbort();
  };

  const StartVideoAndAudio = () => {
    const starVideoAndAudio = async () => {
      await startUserMedia();
    };
    starVideoAndAudio();
  };

  const StartAudio = () => {
    const starAudio = async () => {
      // eslint-disable-next-line
      const dummyDevice: any = { kind: 'audioinput' };
      await startUserMedia(audioDevices.length ? audioDevices[0] : dummyDevice);
    };
    starAudio();
  };

  const StopAudio = () => {
    const stopAudio = async () => {
      await stopUserMedia('audioinput');
    };
    stopAudio();
  };

  const StartVideo = () => {
    const starVideo = async () => {
      // eslint-disable-next-line
      const dummyDevice: any = { kind: 'videoinput' };
      await startUserMedia(videoDevices.length ? videoDevices[0] : dummyDevice);
    };
    starVideo();
  };

  const StopVideo = () => {
    const stopVideo = async () => {
      await stopUserMedia('videoinput');
    };
    stopVideo();
  };

  const MuteAll = () => {
    console.log('MuteAll');
    socket.emit('mute-all', room?.id);
  };

  return (
    <>
      <div style={{ position: 'fixed', left: '-100px', top: '-100px' }}>
        {functs.map((el, id) => (
          <button key={el} id={el} type="button" onClick={handleClick}>
            {el}
          </button>
        ))}
      </div>
    </>
  );
};
export default ExternalController;
